import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const activityKeys = ['textStepperMoreIdeas'];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const TextCustom = makeShortcode("TextCustom");
const TextStepper = makeShortcode("TextStepper");
const layoutProps = {
  activityKeys,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "more-ideas"
    }}>{`More ideas`}</h1>
    <p>{`Here is a list of activities you could do one-on-one with your teenager, together as a family, or with your teen's friends:`}</p>
    <TextCustom align="center" variant="h3" fontFamily="Chewy" customColor="purple" paragraph mdxType="TextCustom">
  The Together List
    </TextCustom>
    <TextStepper id="textStepperMoreIdeas" sequenceSpeed={1200} fadeSpeed={1200} list={[{
      text: 'Get ice cream late at night.'
    }, {
      text: 'Learn to play the guitar together.'
    }, {
      text: 'Listen to a local band.'
    }, {
      text: 'Make homemade pizzas together.'
    }, {
      text: 'Paint a picture, a mural or a room in your house.'
    }, {
      text: 'Plant a vegetable garden.'
    }, {
      text: 'Learn sign language.'
    }, {
      text: 'Go for a hike.'
    }, {
      text: 'Go on a bike ride.'
    }, {
      text: 'Go to a museum or art exhibition.'
    }, {
      text: 'Tell stories around a campfire or barbecue.'
    }, {
      text: 'Visit somewhere that you’ve both never been.'
    }, {
      text: 'Play cards.'
    }, {
      text: 'Visit a local bookstore or library.'
    }, {
      text: 'Play family video games.'
    }, {
      text: 'Go fishing.'
    }, {
      text: 'Play football.'
    }, {
      text: 'Have a culture night – make a meal and learn about another culture.'
    }, {
      text: 'Go to the city.'
    }, {
      text: 'Do some gardening.'
    }, {
      text: 'Play frisbee.'
    }, {
      text: 'Have a family board game night.'
    }, {
      text: 'Create a family photo album.'
    }, {
      text: 'Go camping.'
    }, {
      text: 'Go bowling.'
    }, {
      text: 'Play charades.'
    }, {
      text: 'Get up early to watch the sunrise.'
    }, {
      text: 'Have a formal family dinner – get dressed up and serve special food.'
    }, {
      text: 'Have a night where family members choose the music that will play at dinner time – ask questions about the band, style of music or their fans.'
    }, {
      text: 'Enter a fun run.'
    }, {
      text: 'Go to a theme park.'
    }, {
      text: 'Play sport.'
    }, {
      text: 'Go to the movies.'
    }, {
      text: 'Go out and have lunch.'
    }, {
      text: 'Go to a sports game.'
    }, {
      text: 'Watch a favourite TV show.'
    }]} mdxType="TextStepper" />
    <TextCustom align="right" variant="h3" fontFamily="Chewy" customColor="purple" mdxType="TextCustom">
  Be creative!
    </TextCustom>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      